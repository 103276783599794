:root {
  --hot-pink: #fb5d75;
  --lime-green: #b3e036;
  --yellow: #fcc43c;
  --purple: #7283f0;
  --beige: #f6ece7;
}
.App {
  padding: 100px 0px;
}

@font-face {
  font-family: "Chee";
  src: url(./fonts/CheeeDemo-Sticky.otf) format("opentype");
  font-weight: normal;
  font-display: block;
}

@font-face {
  font-family: "Obviously";
  src: url(./fonts/ObviouslyDemo-WideMedium.otf) format("opentype");
  font-weight: normal;
}

body {
  font-family: "Obviously";
  font-weight: normal;
  font-size: 20px;
  line-height: calc(20px * 1.5);
  overflow-y: hidden;
  background-color: var(--beige);
}

img {
  user-select: none;
  -webkit-user-drag: none;
}

.bold {
  color: white;
  text-decoration: underline;
  text-decoration-color: var(--lime-green);
}

.experience-page.current .bold {
  pointer-events: auto;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-container {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  z-index: 4;
}

.sidebar {
  background-color: var(--purple);
  pointer-events: auto;
  border-left: 4px solid var(--hot-pink);
  align-items: flex-start;
  width: 90px;
  transition: width 300ms cubic-bezier(0.5, 0, 0.75, 0);
  overflow: hidden;
  color: white;
}
.sidebar.expanded {
  width: 50%;
  overflow-y: scroll;
}

.sidebar-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;
  height: 100%;
  position: fixed;
  /* background-color: white; */
}

.sidebar-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  opacity: 0;
  transition: opacity 200ms;
  width: calc(100% - 90px);
  padding: 100px 30px;
}

.sidebar-content.visible {
  transition-delay: 300ms;
  opacity: 1;
}

.sidebar-icons {
  position: absolute;
  top: 30px;
  pointer-events: auto;
  cursor: pointer;
}
.sidebar-icons > img {
  height: 55px;
  margin: 0px 1px;
  transform: rotate(0);
  transform-origin: 50% 40%;
  transition: transform 0.3s ease-in-out 0.1s;
}

.sidebar-icons.expanded > .fork-icon {
  transform: rotate(-40deg);
}
.sidebar-icons.expanded > .knife-icon {
  transform: rotate(40deg);
}

.scene {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  cursor: pointer;
}

.experience {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  pointer-events: none;
  overflow: hidden;
}

.experience-content {
  text-align: left;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  user-select: none;
  -webkit-user-drag: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 100px;
  color: white;
}

.experience-column {
  flex: 1;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.experience-text {
  width: 350px;
  z-index: 1;
}
.experience-page {
  position: absolute;
  opacity: 0;
  transform: translateY(25px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: none;
}
.experience-page.current {
  transition: all 300ms cubic-bezier(0.32, 0, 0.67, 0) 0.25s;
}

.image {
  position: absolute;
  opacity: 0;
  transform: translateY(25px);
  transition: none;
}

.image.current {
  transition: all 400ms cubic-bezier(0.32, 0, 0.67, 0) 0.35s;
}

.media {
  width: 300px;
  border: 4px solid var(--purple);
}
.mural {
  width: 350px;
}

.sticky-header {
  font-family: "Chee";
  text-transform: uppercase;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: var(--hot-pink);
  font-size: 90px;
  margin-top: 60px;
}

.current {
  opacity: 1;
  transform: translateY(0px);
}

.title {
  font-family: "Chee";
  font-size: 48px;
  line-height: calc(48px * 1.1);
  max-width: 500px;
  color: white;
}
.subtitle {
  font-size: 14px;
  max-width: 400px;
  line-height: calc(14px * 1.5);
}

.sidebar-section {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousel-image {
  width: 250px;
  opacity: 0;
}

.carousel-image.current {
  opacity: 1;
}

.image-group {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}

.sidebar-header {
  font-style: italic;
  margin-bottom: 40px;
}

.sidebar-image {
  width: 250px;
  margin: 0px 10px;
}

.image-container {
  margin-top: 40px;
}

svg {
  fill: var(--hot-pink);
  stroke: var(--lime-green);
  stroke-width: 4px;
}
.blob-wrapper {
  position: absolute;
  width: 100%;
}

.blob1 {
  transform: translate(40px, 7px);
}

.blob2 {
  transform: translate(-60px, 10px);
}

.blob3 {
  transform: translateY(10px);
  width: 130%;
}
.blob4 {
  transform: translate(-55px, 15px);
}

.blob5 {
  transform: translate(-75px, 20px);
}

.pasta-image {
  width: 350px;
}
.image-link {
  cursor: pointer;
  border: 5px solid #b3e036;
}

.image-link:hover {
  border-color: #fb5d75;
}
.mouth-container {
  position: fixed;
  top: 30px;
  left: 30px;
  z-index: 3;
  background-color: var(--yellow);
  pointer-events: none;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouth-progress {
  position: absolute;
  fill: none;
  stroke: var(--purple);
}
.progress-ring-circle {
  stroke: var(--purple);
  transition: 0.35s stroke-dashoffset;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.mouth {
  width: 60px;
}

.mobile-experience {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 50px 30px;
  background-color: #fb5d75a8;
  color: white;
  font-size: 14px;
  line-height: calc(14px * 1.5);
  pointer-events: auto;
}
.mobile-text {
  margin-bottom: 30px;
}
.on-the-side {
  pointer-events: auto;
  cursor: pointer;
}

.font-attribution {
  font-size: 10px;
  line-height: 15px;
  color: var(--hot-pink);
  opacity: 0.9;
  text-align: center;
  position: absolute;
  pointer-events: auto;
  z-index: 5;
  bottom: 10px;
  left: 10px;
}
@media (min-width: 320px) {
  .font-attribution {
    color: white;
    font-size: 8px;
  }
  .font-attribution a {
    color: white;
  }
  .sticky-header {
    display: none;
  }
  .sidebar-header {
    font-size: 14px;
    line-height: calc(14px * 1.5);
    margin-bottom: 20px;
  }
  .mouth-container {
    display: none;
  }
  .sidebar.expanded {
    width: 100%;
  }
  .on-the-side {
    display: none;
  }
  .sidebar {
    background-color: transparent;
    border-left: none;
  }
  .sidebar.expanded {
    background-color: var(--purple);
  }
  .sidebar-icons {
    background-color: var(--hot-pink);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 15px;
    right: 15px;
    top: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .title {
    font-size: 36px;
    line-height: calc(36px * 1.1);
    max-width: 300px;
  }
  .subtitle {
    max-width: 280px;
  }
  .sidebar-content {
    width: auto;
    padding: 50px 30px;
  }
  .mobile-experience {
    display: flex;
  }
  .experience-content {
    display: none;
  }
  .knife-icon {
    content: url(./images/knife-icon-pink.png);
  }
}

@media (min-width: 768px) {
  .font-attribution {
    color: var(--hot-pink);
    font-size: 10px;
  }
  .font-attribution a {
    color: var(--hot-pink);
  }
  .mobile-experience {
    display: none;
  }
  .knife-icon {
    content: url(./images/knife_icon.png);
  }
  .sidebar-header {
    font-size: 20px;
    line-height: calc(20px * 1.5);
    margin-bottom: 40px;
  }
  .sticky-header {
    display: block;
    font-size: 70px;
    margin-top: 60px;
  }
  .experience-content {
    display: flex;
    flex-direction: column;
    padding: 100px 0px;
  }
  .mouth-container {
    display: flex;
  }
  .on-the-side {
    display: block;
  }
  .sidebar {
    background-color: var(--purple);
    border-left: 4px solid var(--hot-pink);
  }
  .sidebar-content {
    width: calc(100% - 90px);
  }

  .sidebar-icons {
    background-color: transparent;
    width: auto;
    height: auto;
    border-radius: 0;
    position: absolute;
    top: 30px;
    bottom: auto;
    right: auto;
    box-shadow: none;
  }
  .title {
    font-size: 48px;
    line-height: calc(48px * 1.1);
    max-width: 500px;
  }
  .subtitle {
    max-width: 400px;
  }
  .mouth-container {
    left: 20px;
  }
}

@media (min-width: 880px) {
  .mouth-container {
    left: 30px;
  }
  .sticky-header {
    font-size: 90px;
    margin-top: 60px;
  }
  .sidebar.expanded {
    width: 50%;
  }
  .experience-content {
    flex-direction: row;
    padding: 0px 100px;
  }
  .bowery-image {
    margin-top: 100px;
  }
}

.loading {
  position: absolute;
  background-color: var(--lime-green);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 300ms 1s;
}

.loading.visible {
  opacity: 1;
}

.loading-gif {
  width: 100px;
}

.loading-text {
  margin-top: 30px;
  width: 100px;
}
